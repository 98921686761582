import Cookies from "js-cookie";

async function getConnectedNumberUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_WHATSAPP_CONNECTED_NUMBER;
}

async function getLastConnectedTimeUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_WHATSAPP_LAST_CONNECTED_TIME;
}

async function getCooldownUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_WHATSAPP_COOLDOWN;
}

async function getLastSpokenNumberUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_WHASTAPP_LAST_SPOKEN_NUMBER;
}

async function getModeUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_WHATSAPP_MODE;
}

async function getStatusUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_WHATSAPP_STATUS;
}

async function getQrUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_WHATSAPP_QR;
}

async function getLogoutUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_WHATSAPP_LOGOUT;
}

async function getPairingCodeModeUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_WHATSAPP_PAIRING_CODE_MODE;
}

async function getQrCodeModeUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_WHATSAPP_QR_CODE_MODE;
}

async function getRestartServiceUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_RESTART_SERVICE;
}

export const fetchConnectedNumber = async () => {
  const token = Cookies.get("accessToken");
  const API_URL = await getConnectedNumberUrl();

  const response = await fetch(API_URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Error al obtener el número conectado");
  }

  return response.json();
};

export const fetchLastConnectedTime = async () => {
  const token = Cookies.get("accessToken");
  const API_URL = await getLastConnectedTimeUrl();

  const response = await fetch(API_URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Error al obtener el último tiempo de conexión");
  }

  return response.json();
};

export const fetchCooldown = async () => {
  const token = Cookies.get("accessToken");
  const API_URL = await getCooldownUrl();

  const response = await fetch(API_URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Error al obtener el mensaje de cooldown");
  }

  return response.json();
};

export const fetchLastSpokenNumber = async () => {
  const token = Cookies.get("accessToken");
  const API_URL = await getLastSpokenNumberUrl();

  const response = await fetch(API_URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Error al obtener el último número hablado");
  }

  return response.json();
};

export const fetchMode = async () => {
  const token = Cookies.get("accessToken");
  const API_URL = await getModeUrl();

  const response = await fetch(API_URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Error al obtener el modo");
  }

  return response.json();
};

export const fetchStatus = async () => {
  const token = Cookies.get("accessToken");
  const API_URL = await getStatusUrl();

  const response = await fetch(API_URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Error al obtener el estado de conexión");
  }

  return response.json();
};

export const fetchQr = async () => {
  const token = Cookies.get("accessToken");
  const API_URL = await getQrUrl();

  const response = await fetch(API_URL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Error al obtener el código QR");
  }

  return response.blob();
};

export const postLogout = async () => {
  const token = Cookies.get("accessToken");
  const API_URL = await getLogoutUrl();

  const response = await fetch(API_URL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Error al hacer logout");
  }

  return response.json();
};

export const postPairingCodeMode = async () => {
  const token = Cookies.get("accessToken");
  const API_URL = await getPairingCodeModeUrl();

  const response = await fetch(API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Error al cambiar al modo de código de emparejamiento");
  }

  return response.json();
};

export const postQrCodeMode = async () => {
  const token = Cookies.get("accessToken");
  const API_URL = await getQrCodeModeUrl();

  const response = await fetch(API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Error al cambiar al modo de código QR");
  }

  return response.json();
};

export const postRestartService = async (serviceName) => {
  const token = Cookies.get("accessToken");
  const API_URL = await getRestartServiceUrl();

  const response = await fetch(API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ service_name: serviceName }),
  });

  if (!response.ok) {
    throw new Error("Error al reiniciar el servicio");
  }

  return response.text();
};
