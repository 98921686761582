import React, { useContext, useEffect, useRef, useState } from "react";
import { ContextApp } from "../utils/Context";
import iconBot from "../images/botIcon.svg";
import iconUser from "../images/userIcon.svg";
import { Spin } from 'antd';

function Chat() {
  const {
    message,
    showSlide,
    currentTopic,
    loadChatHistoryForTopic,
    isAwaitingNewResponse,
    setIsAwaitingNewResponse,
    setMessage 
  } = useContext(ContextApp);

  const endOfMessagesRef = useRef(null);
  const [shouldScrollToBottom] = useState(true);
  const [hasLoadedHistory, setHasLoadedHistory] = useState(false);
  const messagesContainerRef = useRef(null);
  const [shouldLoadHistory, setShouldLoadHistory] = useState(false);
  const [config, setConfig] = useState({});
  const [textConfig, setTextConfig] = useState({});
  const [showRetryButton, setShowRetryButton] = useState(false);
  const [showInfoMessage, setShowInfoMessage] = useState(false);

  // Load timing configuration from configuration.json
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/configuration.json");
        const data = await response.json();
        setConfig(data);
      } catch (error) {
        console.error("Error loading config:", error);
      }
    };
    fetchConfig();
  }, []);

  // Load text configuration from text.json
  useEffect(() => {
    const fetchTextConfig = async () => {
      try {
        const response = await fetch("/text.json");
        const data = await response.json();
        setTextConfig(data);
      } catch (error) {
        console.error("Error loading text config:", error);
      }
    };
    fetchTextConfig();
  }, []);

  const chatClass = `w-full h-[85%] overflow-hidden overflow-y-scroll ${showSlide ? "pl-12" : "pl-2"} pr-2`;

  // Scroll to bottom when a new message is received
  useEffect(() => {
    if (shouldScrollToBottom) {
      endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [message, shouldScrollToBottom]);

  // Load chat history for the current topic if needed
  useEffect(() => {
    if (currentTopic && shouldLoadHistory && !hasLoadedHistory) {
      loadChatHistoryForTopic(currentTopic);
      setHasLoadedHistory(true);
      setShouldLoadHistory(false);
    }
  }, [currentTopic, shouldLoadHistory, hasLoadedHistory, loadChatHistoryForTopic]);

  // Show WAITING_TEXT after `MESSAGE_WAIT_TIME` seconds if WebSocket does not respond
  useEffect(() => {
    if (isAwaitingNewResponse) {
      const delayInfoMessage = setTimeout(() => {
        setShowInfoMessage(true); 
      }, config.MESSAGE_WAIT_TIME); // Using timing from JSON config

      return () => clearTimeout(delayInfoMessage); // Clear timer if response is received
    } else {
      setShowInfoMessage(false); // Reset state if no response is awaited
    }
  }, [isAwaitingNewResponse, config.MESSAGE_WAIT_TIME]);

  // Show PROBLEM_TEXT and button after `BUTTON_WAIT_TIME` seconds if WebSocket still does not respond
  useEffect(() => {
    if (isAwaitingNewResponse) {
      const delayButton = setTimeout(() => {
        setShowRetryButton(true);
      }, config.BUTTON_WAIT_TIME); // Using timing from JSON config

      return () => clearTimeout(delayButton); // Clear timer if response is received
    } else {
      setShowRetryButton(false); // Reset state if no response is awaited
    }
  }, [isAwaitingNewResponse, config.BUTTON_WAIT_TIME]);

  // Listen for message changes and disable awaiting response state after a short delay
  useEffect(() => {
    if (message.length > 0 && isAwaitingNewResponse) {
      const timeout = setTimeout(() => {
        setIsAwaitingNewResponse(false); // Allow user to proceed after response is received
      }, 1000); // Short pause to ensure the message is processed

      return () => clearTimeout(timeout);
    }
  }, [message, isAwaitingNewResponse, setIsAwaitingNewResponse]);

  return (
    <div ref={messagesContainerRef} className={chatClass}>
      {message && message.length > 0 ? (
        <div className="flex flex-col w-full justify-end pb-4">
          {message.map((msg, i) => (
            <div key={i} className={`w-full flex items-start mb-4 ${msg.isBot ? "bg-[#F3F3F3]" : "bg-white"}`}>
              <div className="flex w-full justify-center my-4 mx-auto" style={{ maxWidth: "45rem" }}>
                <div className="flex items-start w-full">
                  <img src={msg.isBot ? iconBot : iconUser} alt={msg.isBot ? "Bot" : "User"} className="w-8 h-8 object-cover mr-2 mt-1" />
                  <p className="whitespace-pre-wrap flex-1 text-justify" style={{ whiteSpace: "pre-wrap" }}>
                    {typeof msg.text === 'string' && msg.isBot && msg.text.startsWith(" ") ? msg.text.slice(1) : msg.text}
                  </p>
                </div>
              </div>
            </div>
          ))}
          {isAwaitingNewResponse && (
            <div className="flex flex-col justify-center items-center h-full space-y-4">
              {!showRetryButton && <Spin />}
              {/* Show WAITING_TEXT after `MESSAGE_WAIT_TIME` seconds */}
              {showInfoMessage && !showRetryButton && (
                <p className="relative font-semibold text-gray-800 animate-shine text-2xl mt-6">
                  {textConfig.WAITING_TEXT || "Waiting for bot responses..."}
                </p>
              )}
              {/* Show PROBLEM_TEXT and reload button after `BUTTON_WAIT_TIME` seconds */}
              {showRetryButton && (
                <div className="my-6 text-center">
                  <p className="text-gray-900 text-center text-lg font-semibold">
                    {textConfig.PROBLEM_TEXT || "Looks like there is a problem. Please click the button below to try searching again."}
                  </p>
                  <button
                    onClick={() => window.location.reload()} 
                    className="px-3 py-2 mt-4 bg-sky-500 font-semibold hover:bg-sky-700 text-white shadow-sm rounded focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500">
                    Try Again
                  </button>
                </div>
              )}
            </div>
          )}
          <style jsx>{`
            .animate-shine {
              position: relative;
              overflow: hidden;
              display: inline-block;
              background: linear-gradient(to right, #ffffff, #ffffff 50%, #999999);
              background-size: 200% 100%;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              animation: shine 2s infinite linear;
            }
            @keyframes shine {
              0% { background-position: 100% 0; }
              100% { background-position: -100% 0; }
            }
          `}</style>
          <div ref={endOfMessagesRef} />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-full">
          <img
            src="./static/media/iconchatcontainer.png" 
            alt="Help Icon" 
            className="max-w-20 max-h-20 w-auto h-auto mb-2" />
          <p className="text-black font-normal text-lg">
            {textConfig.CHAT_TEXT_HELP || "How can I assist you today?"}
          </p>
        </div>
      )}
    </div>
  );
}

export default Chat;
