import Cookies from "js-cookie";

async function getApiUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_USERGET; 
}

async function postApiUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return data.API_USERMOD; 
}

export const getUserApi = async (username) => {
  const token = Cookies.get("accessToken");

  try {
    const API_URL = await getApiUrl();
    const response = await fetch(`${API_URL}?username=${username}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('Error en la respuesta:', errorResponse);
      throw new Error(JSON.stringify(errorResponse));
    }

    return response.json();
  } catch (error) {
    console.error('Error al obtener usuario:', error.message);
    throw error;
  }
};

// @TODO faguero Change name to putUserApi
export const postUserApi = async (userData) => {
  const token = Cookies.get("accessToken");

  try {
    const API_URL = await postApiUrl();
    const response = await fetch(API_URL, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userData)
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('Error en la respuesta:', errorResponse);
      throw new Error(JSON.stringify(errorResponse));
    }

    return response.text();
  } catch (error) {
    console.error('Error al actualizar usuario:', error.message);
    throw error;
  }
};